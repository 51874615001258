import React from 'react';
import TablesContent from "../../Components/TablesContent";
import BackBtn from "../../Components/BackBtn";

function Index() {
  return (
    <div className={'tables-page'}>
      <BackBtn/>
      <TablesContent/>
    </div>
  );
}

export default Index;
