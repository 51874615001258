import React, {useEffect, useRef, useState} from 'react';
import styles from './OrdersListContent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Redux/store";
import {Typography} from "@mui/material";
import {Icon} from "@iconify/react";
import {NavLink, useNavigate} from "react-router-dom";
import {formatNumber, getCurrentOrders} from "../../Hooks/hooks";
import {setOrderItems, setOrders} from "../../Redux/Slices/MainSlice";
import moment from "moment";
import axios from "axios";

function Index() {

  const {theme, orders} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken, restaurants} = useSelector((state: RootState) => state.UserSlice);

  const [tabs, setTabs] = useState(['pending', 'accepted']);
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeOrderId, setOrderId] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setOrderItems([]))
    if(restaurants && accessToken) {
      getCurrentOrders({id: restaurants[0]._id, accessToken: accessToken}).then(response => {
        if(response) {
          dispatch(setOrders([...response]))
        }
      })
    }
  }, [restaurants, accessToken, activeTab])

  const cancelOrder = async (id: string) => {
    const order = orders?.find(item => item._id === id);
    if(order && accessToken && restaurants) {
      if(order.type === 'order') {
        try {
          const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/orders/${id}/cancel`, {}, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
          if(result.status === 200) {
            dispatch(setOrders([...orders.filter(item => item._id !== id)]))
            setModal(false)
          }
        } catch (e) {

        }
      } else {
        try {
          const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/officiant_calls/${id}/cancel`, {}, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
          if(result.status === 200) {
            dispatch(setOrders([...orders.filter(item => item._id !== id)]))
            setModal(false)
          }
        } catch (e) {

        }
      }
    }
  }

  const acceptOrder = async (id: string) => {
    const order = orders?.find(item => item._id === id);
    if(order && accessToken && restaurants) {
      if(order.type === 'order') {
        navigate('/edit-order?id=' + activeOrderId + '&from=orders-list')
      } else {
        try {
          const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/officiant_calls/${id}/complete`, {}, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
          if(result.status === 200) {
            dispatch(setOrders([...orders.filter(item => item._id !== id)]))
            setModal(false)
          }
        } catch (e) {

        }
      }
    }
  }

  return (
    <div className={styles.content}>
      <div className={styles.topWrap}>
        <div className={styles.top} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#25293C', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 0px 5px rgba(29, 29, 30, 0.45)'}}>
          {
            tabs.map((item, i) => {
              return (
                <p key={i} onClick={(e) => {

                  setActiveTab(i)
                }} className={`${styles.tab} ${activeTab === i && styles.active}`}>{item === 'accepted' ? 'Подтверждены' : 'Ожидают'}</p>
              )
            })
          }
        </div>
        <div style={{background: theme === 'light' ? '#DBDADE' : '#434968'}} className={styles.underlineWrap}>
          <span style={{transform: `translateX(${activeTab === 0 ? '0' : '100%'})`}} className={styles.underline}/>
        </div>
      </div>
      <div className={styles.orders}>
        {orders.length > 0 &&
          orders?.map(item => {
            if(item.status === tabs[activeTab]) {
              return (
                <div key={item._id} className={styles.order} style={{backgroundColor: theme === 'light' ? '#FFFFFF' : '#25293C', boxShadow: theme === 'light' ? '0px 4px 18px rgba(75, 70, 92, 0.1)' : '0px 4px 20px rgba(15, 20, 34, 0.4)'}}>
                  <p onClick={() => {
                    if(item.status === 'accepted' || item.type === 'order') {
                      navigate('/edit-order?id=' + item._id + '&from=orders-list')
                    } else {
                      setOrderId(item._id)
                      setModal(true)
                    }
                  }} style={{borderRight: theme === 'dark' ? '1px solid #2F3349' : '1px solid rgba(168, 170, 174, 0.16)'}} className={styles.number}>#{formatNumber(item.callNumber)}</p>
                  <div style={{borderRight: theme === 'dark' ? '1px solid #2F3349' : '1px solid rgba(168, 170, 174, 0.16)'}} className={styles.type}>
                    <Icon icon={`tabler:${item.type === 'bill' ? 'receipt' : item.type === 'call' ? 'bell-ringing' : 'tools-kitchen-2'}`}/>
                    <p>{item.type === 'bill' ? 'Счет' : item.type === 'call' ? 'Вызов' : 'Заказ'}</p>
                  </div>
                  <div style={{borderRight: theme === 'dark' ? '1px solid #2F3349' : '1px solid rgba(168, 170, 174, 0.16)'}} className={styles.table}>
                    <Icon icon={'tabler:container'}/>
                    <p>{item.table}</p>
                  </div>
                  <p style={{borderRight: theme === 'dark' ? '1px solid #2F3349' : '1px solid rgba(168, 170, 174, 0.16)'}} className={styles.date}>{moment(item.updatedAt).format('DD.MM.YYYY HH:mm').toString()}</p>
                </div>
              )
            }
        })}
      </div>
      <NavLink to={'/add-order'} className={styles.addBtn}>
        <Icon width={30} icon={'tabler:plus'} color={'#fff'}/>
      </NavLink>
      <div className={`${styles.modal} ${modal && styles.active}`}>
        <span onClick={() => setModal(false)}/>
        <div style={{background: theme === 'light' ? '#F8F9FA' : '#25293C'}} className={styles.modalContent}>
          <button onClick={() => acceptOrder(activeOrderId)} className={styles.green}>Принять</button>
          <button onClick={() => cancelOrder(activeOrderId)} className={styles.red}>Отклонить</button>
          <button onClick={() => setModal(false)} style={{background: theme === 'light' ? '#F8F9FA' : '#25293C'}} className={styles.close}>
            <Icon color={'#4B465C'} icon={'tabler:x'} width={20}/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Index;
