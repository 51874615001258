import React from 'react';
import OrdersListContent from "../../Components/OrdersListContent";

function Index() {
  return (
    <div className={'orders-list-page'}>
      <OrdersListContent/>
    </div>
  );
}

export default Index;
