import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import moonLight from '../../../assets/img/Moon.svg';
import sunLight from '../../../assets/img/Sun.svg';
import styles from './Switch.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../Redux/store";
import {useDispatch} from "react-redux";
import {toggleTheme} from "../../Redux/Slices/MainSlice";

const Android12Switch = styled(Switch)(({ theme }) => (
  {
    padding: 6,
    '& .MuiSwitch-track': {
      borderRadius: 200,
      backgroundColor: 'transparent',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '52%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 20,
      height: 20,
      margin: 1,
      marginTop: '1.5px',
    },
    '& .MuiSwitch-switchBase': {
      padding: '7.5px 9px 9px 9px',

    },
    '& ': {
      paddingRight: 4,
    }
  }
));
export function QrSwitch() {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  const dispatch = useDispatch();

  return (
    <div className={styles.content}>
      <FormGroup >
        <FormControlLabel
          className={`${theme === 'light' ? styles.light : styles.dark} ${theme === 'light' ? styles.globalLight : styles.globalDark}`}
          control={<Android12Switch checked={theme === 'dark'}/>}
          label={''}
          onChange={() => dispatch(toggleTheme())}
          style={{marginRight: 0}}
        />
      </FormGroup>
    </div>
  );
}
