import React from 'react';
import AddOrderContent from "../../Components/AddOrderContent";

function Index() {
  return (
    <div className={'order-page'}>
      <AddOrderContent/>
    </div>
  );
}

export default Index;
