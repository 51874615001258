import React, { useEffect, useRef, useState } from 'react';
import styles from "../Card/Card.module.scss";
import image from "../../assets/img/product_image.png";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { toggleDishModal } from "../../Redux/Slices/MainSlice";
import { motion } from 'framer-motion';
import DishButtons from "../dish/DishButtons";
import { useLocation } from 'react-router-dom';
import Button from '../Button';

function Index() {

  const modal = useSelector((state: RootState) => state.MainSlice.dishModal);
  const cartItems = useSelector((state: RootState) => state.MainSlice.cartItems);
  const item = useSelector((state: RootState) => state.MainSlice.currentItem);
  const theme = useSelector((state: RootState) => state.MainSlice.theme);

  const dispatch = useDispatch();
  const location = useLocation();


  const refContent: any = useRef();

  const [activeOption, setActiveOption] = useState<{
    _id: string,
    title: string,
    price: number
  } | undefined>();

  const [price, setPrice] = useState<number | undefined>()

  const [count, setCount] = useState(1);

  const [activeDops, setDops] = useState<{
    title: string,
    price: number,
    _id: string,
  }[]>([])

  const [addPrice, setAddPrice] = useState(0);


  useEffect(() => {
    let priceLocal = 0;
    if (item && item.activeAdditionals) {
      item.activeAdditionals.map(item => {
        if (item.price) {
          priceLocal += item.price
        }
      })
    } else if (item) {
      activeDops.map(item => {
        if (item.price) {
          priceLocal += item.price
        }
      })
    }

    setAddPrice(priceLocal);
  }, [item, activeDops, activeOption])

  
  useEffect(() => {
    setActiveOption(item?.activeOption ? item?.activeOption : item?.optionsItems ? { _id: `0${item?.optionsItems[0].title}`, title: item?.optionsItems[0].title, price: item?.optionsItems[0].price } : undefined)
    setDops([])
    if(location.pathname.substring(location.pathname.length - 5) === 'order') {
      setCount(item?.count ? item?.count : 1);
      setPrice(item?.price)
      setAddPrice(0)
    } else {
      setCount(1)
      setPrice(item?.activeOption ? item?.activeOption.price + item?.price : item?.optionsItems ? item?.optionsItems[0].price + item?.price : item?.price);
    }
  }, [modal])

  return (
    <div className={`${styles.modal} ${modal && styles.active}`}>
      {
        modal && <div style={{ background: theme === 'light' ? '#F8F9FA' : '#25293C' }} className="product-buttons mobile">
        <Button addPrice={addPrice} count={count} setCount={setCount} activeAdditionals={activeDops} activeOption={activeOption} price={price} unit={item} />
        <div className="total-price__options">
          <p className="modal-title__mobile">{item?.title}</p>
          <p>{item?.price && count * item?.price > 0 ? `Сумма` : ''}</p>
          <p className="price">{location.pathname.substring(location.pathname.length - 5) === 'order' ? item?.price ? `${item?.price} ₽` : '' : price && count * price > 0 ? `${count * (price + addPrice)} ₽` : ''}</p>
        </div>
      </div>
      }
      {
        modal &&
        <span className={'modal-bg'} onClick={() => {
          dispatch(toggleDishModal())
        }} />
      }
      {
        modal &&
        <motion.div className="container" key={'modal-inner'}
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: '0', opacity: 1 }}
          transition={{ duration: 0.2 }} >
          <button className={styles.close} onClick={() => dispatch(toggleDishModal())}>
            <Icon icon={'tabler:x'} width={35} />
          </button>
          <div className={`product-modal ${theme}`} ref={refContent}>
            <div
              className={`product-modal__content ${styles.modalContent}`} style={{ background: theme === 'light' ? '#F8F9FA' : '#25293C' }}>

              <div className="product-image__wrapper" style={{ backgroundImage: `url(https://s3.vkusclick.ru/${item?.image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
              </div>
              <div className="product-info">
                <div className="product-start">
                  <div className="product-title">{item?.title}</div>
                </div>
                <div className={`product-price ${theme === "dark" ? "dark" : ""}`}>{item?.price} ₽</div>
                <div className="product-description">{item?.description}</div>
                <div className="dish-info">

                </div>
                <div className={`product-measures `}>
                  <div className="product-measures__item dark">
                    <div className="product-measures__item-value">{!item?.kilocalories ? '-' : item?.kilocalories}</div>
                    <div className="product-measures__item-name">Ккал</div>
                  </div>
                  <div className="product-measures__item dark">
                    <div className="product-measures__item-value">{!item?.proteins ? '-' : item?.proteins}</div>
                    <div className="product-measures__item-name">Белки</div>
                  </div>
                  <div className="product-measures__item dark">
                    <div className="product-measures__item-value">{!item?.fats ? '-' : item?.fats}</div>
                    <div className="product-measures__item-name">Жиры</div>
                  </div>
                  <div className="product-measures__item dark">
                    <div className="product-measures__item-value">{!item?.carbohydrates ? '-' : item?.carbohydrates}</div>
                    <div className="product-measures__item-name">Углеводы</div>
                  </div>
                </div>
              </div>
              <div className="product-buttons">
                <Button addPrice={addPrice} count={count} setCount={setCount} activeAdditionals={activeDops} activeOption={activeOption} price={price} unit={item} />
                <div className="total-price__options">
                  <p className="modal-title__mobile">{item?.title}</p>
                  <p>{item?.price && count * item?.price > 0 ? `Сумма` : ''}</p>
                  <p className="price">{location.pathname.substring(location.pathname.length - 5) === 'order' ? item?.price ? `${item?.price} ₽` : '' : price && count * price > 0 ? `${count * (price + addPrice)} ₽` : ''}</p>
                </div>
              </div>
              {
                item?.allergens && item?.allergens.length > 0 &&
                <div className="product-allergens">
                  <h4 className="product-allergens__title">Аллергены</h4>
                  <div className="product-allergens__items">
                    {item?.allergens.map(all => {
                      return <div className={`product-allergens__item ${theme === "dark" ? "dark" : ""}`}>{all}</div>
                    })}
                  </div>
                </div>
              }
              {
                item?.ingredients && item?.ingredients.length > 0 &&
                <div className="product-ingredients">
                  <h4 className="product-ingredients__title">Ингридиенты</h4>
                  <div className="product-ingredients__text dark">{item?.ingredients}</div>
                </div>
              }
              {
                item?.optionsItems &&
                <div className="product-options">
                  <h4 className="product-options__title">Опции</h4>
                  {

                    location.pathname.substring(location.pathname.length - 5) === 'order' ?
                      <p>{item?.activeOption?.title}</p> :
                      <div className="options">
                        {
                          item?.optionsItems.map((option, i) => {
                            return (
                              <div className={'option'} onClick={() => {
                                setActiveOption({
                                  _id: i + option.title,
                                  title: option.title,
                                  price: option.price
                                })
                                setPrice(option.price ? item?.price + option.price : item?.price)
                              }} key={option.title + option.price} style={{ borderColor: theme === "dark" ? '#5C5F66' : '#DEE2E6' }}>
                                <div className="option-info">
                                  <p className="option-title">{option.title}</p>
                                  <p className="option-quantity">{option.quantity ? `(${option.quantity}${option.measure === 'grams' ? "г" : option.measure === 'kilograms' ? 'кг' : option.measure === 'milliliters' ? 'мл' : option.measure === 'liters' ? 'л' : option.measure === 'pieces' ? 'шт' : option.measure === 'portions' ? 'шт' : ''})` : ''}</p>
                                  <p className="option-price">{option.price ? `+${option.price}₽` : ''}</p>
                                </div>
                                <div className="option-radio" style={{ borderColor: theme === "dark" ? '#5C5F66' : '#DEE2E6' }}>
                                  {
                                    activeOption && activeOption._id === i + option.title &&
                                    <span className="active-dot"></span>
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                  }

                </div>
              }
              {
                item?.additionallItems &&
                <div className="product-additionall">
                  {
                    location.pathname.substring(location.pathname.length - 5) === 'order' && item?.activeAdditionals && item?.activeAdditionals.length === 0 ?
                      <></> :
                      <h4 className="product-additionall__title">Дополнительно</h4>
                  }
                  {
                    location.pathname.substring(location.pathname.length - 5) === 'order' ?
                      <p>{item?.activeAdditionals?.map(item => item.title).join(', ')}</p> :
                      <div className="additionalls">
                        {
                          item?.additionallItems.map((option, i) => {
                            return (
                              <div className={'additionall'} onClick={() => {
                                if (!activeDops.map(item => item._id).includes(i + option.title)) {
                                  setDops([...activeDops, { _id: i + option.title, price: option.price, title: option.title }])
                                } else {
                                  setDops([...activeDops.filter(item => item._id !== (i + option.title))])
                                }
                              }} key={option.title + option.price} style={{ borderColor: theme === "dark" ? '#5C5F66' : '#DEE2E6' }}>
                                <div className="additionall-info">
                                  <p className="additionall-title">{option.title}</p>
                                  <p className="additionall-quantity">{option.quantity ? `(${option.quantity}${option.measure === 'grams' ? "г" : option.measure === 'kilograms' ? 'кг' : option.measure === 'milliliters' ? 'мл' : option.measure === 'liters' ? 'л' : option.measure === 'pieces' ? 'шт' : option.measure === 'portions' ? 'шт' : ''})` : ''}</p>
                                  <p className="additionall-price">{option.price ? `+${option.price}₽` : ''}</p>
                                </div>
                                <div className="additionall-radio" style={{ borderColor: theme === "dark" ? '#5C5F66' : '#DEE2E6' }}>
                                  {
                                    activeDops && activeDops.map(item => item._id).includes(i + option.title) &&
                                    <>
                                      <span className="active-dot"></span>
                                      <svg className="option-check" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m5 12l5 5L20 7" /></svg>
                                    </>

                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                  }

                </div>
              }
            </div>
          </div>
        </motion.div>
      }

    </div>
  );
}

export default Index;
