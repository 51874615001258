import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './MenuContent.module.scss';
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Redux/store";
import {Typography} from "@mui/material";
import Card from "../Card";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {getCategories, getDishes, getPlaceInfo, getPopularId} from "../../Hooks/hooks";
import {
  setActiveCategory, setCartItems, setCartLength,
  setCategories,
  setCurrentOrderId, setOrderItems,
  setPopularProductsId,
  setProducts, setTotalCartPrice
} from "../../Redux/Slices/MainSlice";
import qs from "qs";
import axios from "axios";

function Index({refMenu}: {refMenu: any}) {

  const {theme, cartTotalPrice, categories, activeCategory, popularProductsId, products, cartItems, orderItems} = useSelector((state: RootState) => state.MainSlice);
  const {accessToken, restaurants} = useSelector((state: RootState) => state.UserSlice);

  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);

  const ref: any = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchValue, changeSearch] = useState('');

  const getOrderInfo = async () => {
    if(restaurants && accessToken) {
      try {
        const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/orders/${qs.parse(location.search.substring(1)).id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        dispatch(setCurrentOrderId(result.data._id))
        getDishes(restaurants[0]._id).then(products => {
          if(products) {
            dispatch(setProducts([...products]))
          }
          let arr = products?.map(item => {
            if (result.data.dishes.map((id: any) => id._id).includes(item._id)) {
              return {
                ...item, count: result.data.dishes.filter((id: any) => id._id === item._id)[0].quantity, activeOption: result.data.dishes.find((id: any) => id._id === item._id)?.option ? { _id: `0${result.data.dishes.find((id: any) => id._id === item._id)?.option}`, title: result.data.dishes.find((id: any) => id._id === item._id)?.option, price: item.optionsItems ? item.optionsItems.find((opt: any) => opt.title === result.data.dishes.find((id: any) => id._id === item._id)?.option).price : 0 } : {title: '', price: 0}, activeAdditionals: result.data.dishes.find((id: any) => id._id === item._id)?.additionalls ? [...result.data.dishes.find((id: any) => id._id === item._id)?.additionalls.map((add: any, i: number) => {
                  return { _id: `${item.additionallItems.map((item: any) => item.title).indexOf(add)}${add}`, price: item.additionallItems ? item.additionallItems.find((opt: any) => opt.title === add).price : 0, title: add }
                })] : []
              }
            }
          }).filter(item => item)
          let addPrice = 0;
          arr?.map((temp: any) => {
            temp.activeAdditionals.map((add: any) => addPrice += add.price)
            temp.activeAdditionals.sort((a: any, b: any) => a.title > b.title ? 1 : -1)
          })
          arr = arr ? [...arr.map((temp: any) => {
            return { ...temp, price: temp.price + (temp.activeOption ? temp.activeOption.price : 0) + addPrice }
          })] : []
          if(arr) {
            dispatch(setOrderItems([...arr]));
          }
        })

      } catch (e) {

      }
    }
  }

  const loadLocal = useCallback(() => {
    if(restaurants && restaurants[0]._id === localStorage.getItem('restaurantId')) {
      if(localStorage.getItem('cartItems')) {
        // @ts-ignore
        dispatch(setCartItems(JSON.parse(localStorage.getItem('cartItems'))))
        // @ts-ignore
        dispatch(setCartLength(JSON.parse(localStorage.getItem('cartLength'))))
        // @ts-ignore
        dispatch(setTotalCartPrice(JSON.parse(localStorage.getItem('cartTotalPrice'))))
      }
    } else if(restaurants) {
      localStorage.setItem('restaurantId', restaurants[0]._id)
    }
  }, [restaurants])


  useEffect(() => {
    if(qs.parse(location.search.substring(1)).id) {
      if(accessToken && restaurants && cartItems.length === 0) {
        getOrderInfo()
      }
    } else {
      if(qs.parse(location.search.substring(1)).from !== 'create-order') {
        navigate('/');
      } else {
        loadLocal();
      }
    }
  }, [accessToken, restaurants, orderItems])

  const start = () => {
    if(restaurants && accessToken) {
      getDishes(restaurants[0]._id).then(products => {
        getCategories(restaurants[0]._id).then(categories => {
          if(categories) {
            const arr = (products?.map(product => {
              if (categories.map(item => item._id).includes(product.categoryId) && product.active && product.image !== '') {
                if (categories.filter(item => item._id === product.categoryId)[0]._id !== undefined) {
                  return (categories.filter(item => item._id === product.categoryId)[0]._id);
                }
              }
            }).filter(item => item));
            dispatch(setCategories([...categories.filter(item => arr?.includes(item._id))]))
            if(products) {
              dispatch(setProducts([...products.filter(item => arr?.includes(item.categoryId))]))
            }
          }
        })
        getPopularId(restaurants[0]._id).then(id => {
          if(id) {
            dispatch(setPopularProductsId([...id]))
          }
        })
      })
    }
  }

  useEffect(() => {
    start()
  }, [accessToken, restaurants])

  const [popularActive, setPopularActive] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if(popularProductsId?.length && popularProductsId?.length > 0 && products) {
      popularProductsId?.map(item => {
        if(products?.filter(product => product._id === item) && products?.filter(product => product._id === item).length > 0) {
          dispatch(setActiveCategory(categories[0]));
          setPopularActive(true);
        } else {
          dispatch(setActiveCategory(categories[1]));
        }
      })
    } else {
      setPopularActive(false);
    }
  }, [popularProductsId, products])


  const Visible = function (target: any, index: number) {
    const targetPosition = {
        top: window.pageYOffset + target.getBoundingClientRect().top,
        left: window.pageXOffset + target.getBoundingClientRect().left,
        right: window.pageXOffset + target.getBoundingClientRect().right,
        bottom: window.pageYOffset + target.getBoundingClientRect().bottom
      },
      windowPosition = {
        top: window.pageYOffset,
        left: window.pageXOffset,
        right: window.pageXOffset + document.documentElement.clientWidth,
        bottom: window.pageYOffset + document.documentElement.clientHeight
      };
    if (targetPosition.left < windowPosition.left + 18){
      if(index === 0) {
        ref.current.scrollLeft += targetPosition.left - (windowPosition.left + 25);
      } else {
        ref.current.scrollLeft -= target.getBoundingClientRect().width + 50;
      }
    } else if (targetPosition.right > windowPosition.right - 20) {
      if(index === categories.length - 1) {
        ref.current.scrollLeft += targetPosition.right + (windowPosition.right + 25);
      } else {
        ref.current.scrollLeft += target.getBoundingClientRect().width + 30;
      }
    }
  };


  // @ts-ignore
  return (
    <div className={styles.content}>
      <div className={styles.searchBar} style={{borderColor: theme === 'light' ? '#DBDADE' : '#434968'}}>
        <Icon width={20} icon={'tabler:search'} color={theme === 'light' ? '#A5A3AE' : '#7983BB' }/>
        <input value={searchValue} onChange={(e) => changeSearch(e.target.value)} type="text" placeholder={'Поиск'}/>
      </div>
      <div className={`${styles.tabs} ${theme == 'dark' && styles.dark}`} ref={ref}>
        <div>
          { categories &&
            categories.map((item, i) => {
              if(item.title === 'Популярные') {
                if (popularActive) {
                  return (
                    <p onClick={(e: any) => {
                      Visible(e.target, i)
                      setActiveTabIndex(i)
                      dispatch(setActiveCategory(item))
                    }}
                                style={{background: theme === 'light' ? '#ECECEE' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), #8692D0'}}
                                key={item._id + i}
                                className={`${styles.tab} ${item._id === activeCategory?._id ? styles.active : ''}`}>{item.title}</p>
                  )
                }
              } else {
                return (
                  <p onClick={(e: any) => {
                    Visible(e.target, i)
                    setActiveTabIndex(i)
                    dispatch(setActiveCategory(item))
                  }}
                              style={{background: theme === 'light' ? '#ECECEE' : 'linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), #8692D0'}}
                              key={item._id + i}
                              className={`${styles.tab} ${item._id === activeCategory?._id ? styles.active : ''}`}>{item.title}</p>
                )
              }
              }
            )
            }
        </div>
      </div>
      <div className={styles.menu}>
        {/*@ts-ignore*/}
        <p>{activeCategory?.title}</p>
        <div className={styles.items}>
          { searchValue === ''  ?
            /* @ts-ignore*/
            products?.filter(item => activeCategory && activeCategory.title === 'Популярные' ? popularProductsId?.includes(item._id) : item.categoryId === activeCategory?._id).map(item => {
              return (
                <Card unit={item}/>
              )
            }) :
            products?.filter(item => item.title.toLowerCase().includes(searchValue.toLowerCase())).map(item => {
              return (
                <Card unit={item}/>
              )
            })
          }
        </div>
      </div>
      {
        cartTotalPrice > 0 &&
        <div className={styles.btnWrap} style={{background: theme === 'light' ? '#FFFFFF' : '#25293C'}}>
          <NavLink className={styles.toOrder} to={qs.parse(location.search.substring(1)).from !== 'create-order' ? '/edit-order?id=' + qs.parse(location.search.substring(1)).id + '&from=menu' : '/add-order'}>В заказ {cartTotalPrice} ₽</NavLink>
        </div>
      }
    </div>
  );
}

export default Index;
