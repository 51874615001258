import React, { useEffect, useState } from 'react';
import styles from './EditOrderContent.module.scss'
import BackBtn from "../BackBtn";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Icon } from "@iconify/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import qs from 'qs';
import { formatNumber, getDishes } from "../../Hooks/hooks";
import {
  addCountProductCart,
  removeCountFromCart,
  removeFromCart,
  setCurrentItem,
  setCurrentOrderId,
  setOrderItems, setOrders,
  setProducts,
  toggleDishModal
} from "../../Redux/Slices/MainSlice";

function Index() {

  const { currentOrderId, theme, cartTotalPrice, cartItems, orders } = useSelector((state: RootState) => state.MainSlice);
  const { accessToken, restaurants, user } = useSelector((state: RootState) => state.UserSlice);

  const [order, setOrder] = useState<any>()

  const location = useLocation();
  const dispatch = useDispatch();

  const getOrderInfo = async () => {
    if (restaurants && accessToken) {
      try {
        const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/orders/${qs.parse(location.search.substring(1)).id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        setOrder(result.data);
        dispatch(setCurrentOrderId(result.data._id))
        getDishes(restaurants[0]._id).then(products => {
          if (products) {
            dispatch(setProducts([...products]))
          }
          let arr = products?.map(item => {
            if (result.data.dishes.map((id: any) => id._id).includes(item._id)) {
              return {
                ...item, count: result.data.dishes.filter((id: any) => id._id === item._id)[0].quantity, activeOption: result.data.dishes.find((id: any) => id._id === item._id)?.option ? { _id: `0${result.data.dishes.find((id: any) => id._id === item._id)?.option}`, title: result.data.dishes.find((id: any) => id._id === item._id)?.option, price: item.optionsItems ? item.optionsItems.find((opt: any) => opt.title === result.data.dishes.find((id: any) => id._id === item._id)?.option).price : 0 } : { title: '', price: 0 }, activeAdditionals: result.data.dishes.find((id: any) => id._id === item._id)?.additionalls ? [...result.data.dishes.find((id: any) => id._id === item._id)?.additionalls.map((add: any, i: number) => {
                  return { _id: `${item.additionallItems.map((item: any) => item.title).indexOf(add)}${add}`, price: item.additionallItems ? item.additionallItems.find((opt: any) => opt.title === add).price : 0, title: add }
                })] : []
              }
            }
          }).filter(item => item)
          let addPrice = 0;
          arr?.map((temp: any) => {
            temp.activeAdditionals.map((add: any) => addPrice += add.price)
            temp.activeAdditionals.sort((a: any, b: any) => a.title > b.title ? 1 : -1)
          })
          arr = arr ? [...arr.map((temp: any) => {
            return { ...temp, price: temp.price + (temp.activeOption ? temp.activeOption.price : 0) + addPrice }
          })] : []
          if (result.data && arr) {
            if (currentOrderId === qs.parse(location.search.substring(1)).id && qs.parse(location.search.substring(1)).from !== 'orders-list') {

            } else {
              dispatch(setOrderItems([...arr]));
            }
          }
        })

      } catch (e) {

      }
    }
  }

  useEffect(() => {
    getOrderInfo();
  }, [restaurants, accessToken])

  const navigate = useNavigate();

  const [wishes, changeWishes] = useState('');

  const acceptOrder = async () => {
    if (accessToken && restaurants) {
      try {
        // const edit = await axios.put(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/orders/${qs.parse(location.search.substring(1)).id}`, {
        //   officiantId: order.officiantId,
        //   table: order.table,
        //   wishes: wishes,
        //   dishes: [...cartItems.map(item => {
        //     if (item.activeOption) {
        //       if (item.activeAdditionals && item.activeAdditionals.length > 0) {
        //         return { _id: item._id, quantity: item.count, option: item.activeOption.title, additionalls: [...item.activeAdditionals.map((item: any) => item.title)] }
        //       }
        //       return { _id: item._id, quantity: item.count, option: item.activeOption.title }
        //     } else {
        //       if (item.activeAdditionals && item.activeAdditionals.length > 0) {
        //         return { _id: item._id, quantity: item.count, additionalls: [...item.activeAdditionals.map((item: any) => item.title)] }
        //       }
        //       return { _id: item._id, quantity: item.count }
        //     }
        //   })]
        // }, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`
        //   }
        // })
        const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/orders/${qs.parse(location.search.substring(1)).id}/accept`, {}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (result.status === 200) {
          dispatch(setOrderItems([]));
          navigate('/orders-list')
        }
      } catch (e) {

      }
    }
  }

  useEffect(() => {
    changeWishes(order?.wishes);
  }, [order])


  const cancelOrder = async (id: string) => {
    if (accessToken && restaurants) {
      try {
        const result = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/orders/${id}/cancel`, {}, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (result.status === 200) {
          navigate('/orders-list')
        }
      } catch (e) {

      }
    }
  }

  const showProductModalHandler = (value: boolean, unit: any) => {
    dispatch(setCurrentItem(unit))
    dispatch(toggleDishModal());
  }

  return (
    <>
      <BackBtn />
      {
        order &&
        <div className={styles.content}>
          <div className={styles.side}>
            <div className={styles.top}>
              <p className={styles.number}>Заказ #{formatNumber(order.orderNumber)}</p>
              <div className={styles.tableWrap}>
                <p>Стол</p>
                <p className={styles.tableNum} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>{order.table}</p>
              </div>
            </div>
            <div className={`${styles.orderItems} ${theme === 'light' ? styles.light : styles.dark}`}>
              {
                cartItems.map(item => {
                  return (
                    <div key={item._id} className={styles.order} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                      <div onClick={() => showProductModalHandler(true, { ...item })} className={styles.imgWrap} style={{ backgroundImage: `url(${'https://s3.vkusclick.ru/' + item.image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                      </div>
                      <div className={styles.info}>
                        <p className={styles.orderTitle}>{item.title}</p>
                        <div className={styles.descr}>
                          <div>
                            <Icon icon={'ic:baseline-access-time'} color={'#FF9F43'} width={12} />
                            <p>{item.servingTime} мин</p>
                          </div>
                          <div>
                            <Icon icon={'bx:dish'} color={'#FF9F43'} width={12} />
                            <p>{item.quantity} {item.measure === 'grams' ? "г" : item.measure === 'kilograms' ? 'кг' : item.measure === 'milliliters' ? 'мл' : item.measure === 'liters' ? 'л' : item.measure === 'pieces' ? 'шт' : item.measure === 'portions' ? 'шт' : 'мин'}</p>
                          </div>
                        </div>
                        {
                          ((item.activeOption && item.activeOption.title !== '') || (item.activeAdditionals && item.activeAdditionals.length > 0)) &&
                          <div className="options">
                            <p>{`${item.activeOption ? item.activeOption.title : ''}${item.activeAdditionals && item.activeAdditionals.length > 0 ? item.activeOption ? ', ' + item.activeAdditionals.map((item: any) => item.title).join(', ') : item.activeAdditionals.map((item: any) => item.title).join(', ') : ''}`}</p>
                          </div>
                        }
                        <div className={styles.counter} style={{ width: order?.status !== 'pending' ? 'auto' : '' }}>
                          {
                            order?.status === 'pending' &&
                            <button onClick={() => dispatch(removeCountFromCart(item))} style={{ background: theme === 'dark' ? '#434968' : '#DFDFE3' }}>
                              <Icon color={theme === 'light' ? '#8D8B98' : '#9A9FBA'} icon={'ic:twotone-minus'} />
                            </button>
                          }
                          <p style={{ width: order?.status !== 'pending' ? 'auto' : '', background: theme === 'dark' ? '#2F3349' : '#F8F7FA', borderRadius: order?.status !== 'pending' ? '8px' : '', fontSize: order?.status !== 'pending' ? '15px' : '', padding: order?.status !== 'pending' ? '5px 10px' : '' }}>{order?.status !== 'pending' && <span>Количество: </span>} {item.count}</p>
                          {
                            order?.status === 'pending' &&
                            <button onClick={() => dispatch(addCountProductCart(item))} style={{ background: theme === 'dark' ? '#434968' : '#DFDFE3' }}>
                              <Icon color={theme === 'light' ? '#8D8B98' : '#9A9FBA'} icon={'ic:twotone-plus'} />
                            </button>
                          }
                        </div>
                      </div>
                      {
                        order?.status === 'pending' &&
                        <button onClick={() => dispatch(removeFromCart(item))} className={styles.close}>
                          <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} width={20} icon={'material-symbols:close-rounded'} />
                        </button>
                      }
                      <p className={styles.price}>{item.price} ₽</p>
                    </div>
                  )
                })
              }
            </div>
            {
              order?.status === 'pending' &&
              <NavLink to={'/menu?id=' + qs.parse(location.search.substring(1)).id} className={styles.addBtn}>
                <Icon icon={'tabler:plus'} width={20} color={'#FF9F43'} />
                <p>Добавить в заказ</p>
              </NavLink>
            }
          </div>
          <div className={styles.side}>
            <div className={styles.inputWrap}>
              <p className={styles.inputTitle}>Комментарии к заказу</p>
              <textarea disabled={order?.status === 'pending'} value={wishes} onChange={(e) => {
                if (order?.status === 'pending') {
                  changeWishes(e.target.value)
                }
              }} placeholder={'Комментарии'} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}></textarea>
            </div>
            <div className={styles.totalPrice}>
              <div className={styles.top} style={{ color: theme === 'light' ? '#C6C6C8' : '#7983BB', borderColor: theme === 'light' ? '#C6C6C8' : '#434968' }}>
                <p>Сумма заказа</p>
                <p>{cartTotalPrice} ₽</p>
              </div>
              <div className={styles.price}>
                <p>Итого</p>
                <p>{cartTotalPrice} ₽</p>
              </div>
            </div>
            {
              order?.status === 'pending' &&
              <div className={styles.btns}>
                {
                  cartTotalPrice > 0 &&
                  <button onClick={acceptOrder} className={styles.green}>Принять</button>
                }
                <button onClick={() => cancelOrder(order?._id)} className={styles.red} style={{ maxWidth: cartTotalPrice <= 0 ? 'none' : '', marginLeft: cartTotalPrice <= 0 ? '0' : '' }}>Отклонить</button>
              </div>
            }
          </div>
        </div>
      }
    </>
  );
}

export default Index;
