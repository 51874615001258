import React, {useEffect, useLayoutEffect, useState} from 'react';
import './App.scss';
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./Redux/store";
import {GlobalStyles} from "@mui/material";
import {changeTheme, setHeaderVisible, toggleDishModal, toggleTheme} from "./Redux/Slices/MainSlice";
import OrdersListPage from "./Pages/OrdersListPage";
import Header from "./Layout/Header";
import EditOrderPage from "./Pages/EditOrderPage";
import AddOrderPage from "./Pages/AddOrderPage";
import TablesPage from "./Pages/TablesPage";
import {getAccessToken, getAuth} from "./Hooks/hooks";
import {setCompany, setRestaurants, setTokens, setUser} from "./Redux/Slices/UserSlice";
import Menu from "./Pages/MenuPage";
import Modal from "./Components/Modal";

function App() {

  const {theme, headerVisible, cartTotalPrice} = useSelector((state: RootState) => state.MainSlice);
  const {user} = useSelector((state: RootState) => state.UserSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const modal = useSelector((state: RootState) => state.MainSlice.dishModal);
  const [start, setStart] = useState(true);
  const [timer, setTimer] = useState<any>()

  useLayoutEffect(() => {
    dispatch(setHeaderVisible(location.pathname !== '/sign-in'))
  }, [location])

  useEffect(() => {
    // @ts-ignore
    dispatch(changeTheme(localStorage.getItem('theme') ? localStorage.getItem('theme') === 'light' ? 'light' : 'dark' : 'light' ));
    if(user === null) {
      // setLoading(true);
      if(localStorage.getItem('refreshToken')) {
        getAccessToken({refreshToken: localStorage.getItem('refreshToken') as string}).then(
          tokens => {
            if(tokens instanceof Error) {
              if(location.pathname !== '/sign-in' && location.pathname !== '/activation' && location.pathname !== '/registration' && location.pathname !== '/add-restaurant') {
                if(!localStorage.getItem('refreshToken')) {
                  navigate('/auth-error')
                } else {
                  navigate('/sign-in')
                  // setLoading(false)
                }
              }
              // setLoading(false)
            } else {
              dispatch(setTokens({...tokens}))
              getAuth({accessToken: tokens.accessToken}).then(
                response => {
                  if (response.type !== 'error') {
                    dispatch(setUser({...response.user}));
                    if(response.user.role === 'director') {
                      dispatch(setCompany({...response.company}));
                    }
                    if (response.user?.restaurantsId.length !== 0) {
                      dispatch(setRestaurants([response.restaurants]));
                    }
                  } else {
                  }
                  // setLoading(false)
                })
            }
          }
        )

      }
      else {
        // setLoading(false)
        if(location.pathname !== '/sign-in' && location.pathname !== '/activation' && location.pathname !== '/registration' && location.pathname !== '/add-restaurant' && location.pathname !== '/reset-password') {
          navigate('/sign-in')
        }
      }

    }
    else if(location.pathname !== '/sign-in' && location.pathname !== '/add-restaurant' && location.pathname !== '/registration' && location.pathname !== '/activation') {
      if (!user.emailConfirmed && user.role === 'director') {
        navigate('/activation');
      } else if(user.restaurantsId.length === 0) {
        navigate('/add-restaurant');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div className={`App ${theme}`} style={{height: modal ? '80vh' : '', overflowY: modal ? 'hidden' : 'auto'}}>
      <div className="container" >
        <GlobalStyles styles={{body: {backgroundColor: theme === 'light' ? '#F8F7FA' : '#25293C'}}}/>
        {headerVisible && <Header/>}
        <Routes>
          <Route path={'/'} element={<Navigate to={'/orders-list'}/>}/>
          <Route path={'/sign-in'} element={<LoginPage/>}/>
          <Route path={'/orders-list'} element={<OrdersListPage/>}/>
          <Route path={'/edit-order'} element={<EditOrderPage/>}/>
          <Route path={'/add-order'} element={<AddOrderPage/>}/>
          <Route path={'/tables'} element={<TablesPage/>}/>
          <Route path={'/menu'} element={<Menu/>}/>
        </Routes>
      </div>
        <div>
          <Modal />
        </div>
    </div>
  );
}

export default App;
