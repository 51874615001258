import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import item from '../../assets/img/orderItem.png';
import item2 from '../../assets/img/img2.png';
import { byField } from "../../Hooks/sort";

export interface MainState {
  theme: 'dark' | 'light',
  headerVisible: boolean,
  cartItems: any[],
  tables: {
    id: number,
    number: number,
    status: 'selected' | 'unattended' | 'unselected' | 'already'
  }[],
  orders: {
    _id: string,
    callNumber: number,
    officiantId: string,
    status: string,
    table: number,
    type: string,
    updatedAt: string,
  }[],
  activeCategory?: {
    _id: string,
    title: string,
    priority: number,
    active: true,
    image: string,
    updatedAt: string
  } | { _id: string, title: 'Популярные' },
  orderItems: {
    _id: string,
    categoryId: string,
    title: string,
    description: string,
    price: number,
    oldPrice: number,
    quantity: number,
    measure: string,
    servingTime: number,
    kilocalories: number,
    proteins: number,
    fats: number,
    carbohydrates: number,
    ingredients: string,
    allergens: [
      string
    ],
    active: boolean,
    image: string,
    updatedAt: string,
    count: number
  }[],
  menuItems: {
    id: number,
    img: string,
    title: string,
    time: string,
    price: number,
    count: number,
    weight: number,
  }[],
  headerModal: boolean,
  dishModal: boolean,
  currentItem?: {
    optionsItems: {
      title:	string
      price:	number
      quantity:	number
      measure:	string
    }[] | null
    additionallItems: {
      title:	string
      price:	number
      quantity:	number
      measure:	string
    }[] | null,
    activeOption?: {
      _id: string,
      price: number,
      title: string,
    },
    activeAdditionals?: {
      _id: string,
      price: number,
      title: string,
    }[]
    _id: string,
    categoryId: string,
    title: string,
    description: string,
    price: number,
    oldPrice: number,
    quantity: number,
    measure: string,
    servingTime: number,
    kilocalories: number,
    proteins: number,
    fats: number,
    carbohydrates: number,
    ingredients: string,
    allergens: [
      string
    ],
    active: boolean,
    image: string,
    updatedAt: string,
    count: number
  },
  categories: {
    _id: string,
    title: string,
    priority: number,
    active: true,
    image: string,
    updatedAt: string
  }[],
  products?: {
    _id: string,
    categoryId: string,
    title: string,
    description: string,
    price: number,
    oldPrice: number,
    quantity: number,
    measure: string,
    servingTime: number,
    kilocalories: number,
    proteins: number,
    fats: number,
    carbohydrates: number,
    ingredients: string,
    allergens: [
      string
    ],
    active: boolean,
    image: string,
    updatedAt: string
  }[],
  popularProductsId?: string[],
  cartLength: number,
  cartTotalPrice: number,
  currentOrderId: string,
  table: string,
}

const initialState: MainState = {
  currentOrderId: '',
  cartLength: 0,
  cartTotalPrice: 0,
  cartItems: [],
  theme: 'dark',
  headerVisible: true,
  dishModal: false,
  tables: [],
  orders: [],
  orderItems: [],
  menuItems: [],
  categories: [],
  headerModal: false,
  table: ''
}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setTable: (state, { payload }) => {
      state.table = payload;
    },
    toggleTheme: state => {
      state.theme = state.theme === 'light' ? 'dark' : 'light'
      localStorage.setItem('theme', state.theme);
    },
    setHeaderVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.headerVisible = payload;
    },
    setHeaderModal: (state, { payload }: PayloadAction<boolean>) => {
      state.headerModal = payload;
    },
    toggleDishModal: state => {
      state.dishModal = !state.dishModal;
    },
    changeTheme: (state, { payload }) => {
      state.theme = payload;
      localStorage.setItem('theme', payload);
    },
    setOrders: (state, { payload }) => {
      state.orders = [...payload];
    },
    setCategories(state, { payload }) {
      state.categories = [{ _id: '0', title: 'Популярные' }, ...payload.filter((item: any) => item.active).sort(byField('priority')).reverse()]
    },
    setProducts(state, { payload }) {
      state.products = [...payload.filter((item: any) => item.active && item.image !== '')]
    },
    setPopularProductsId(state, { payload }) {
      state.popularProductsId = [...payload];
    },
    setActiveCategory(state, { payload }) {
      state.activeCategory = payload;
    },
    setCurrentItem(state, { payload }) {
      state.currentItem = payload;
    },
    addToCart(state, { payload }) {
      if (state.cartItems.length > 0) {
        if ((payload.activeOption || (payload.activeAdditionals && payload.activeAdditionals.length > 0))) {
          if (state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`)) {
            // @ts-ignore
            state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`).count += payload.count
            // @ts-ignore
            state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`).price += payload.price
          } else {
            state.cartItems.push({ ...payload });
          }
        } else {
          if (state.cartItems.find(item => item._id === payload._id)) {
            // @ts-ignore
            state.cartItems.find(item => item._id === payload._id).count += payload.count
            // @ts-ignore
            state.cartItems.find(item => item._id === payload._id).price += payload.price
          } else {
            state.cartItems.push({ ...payload });
          }
        }
      } else {
        state.cartItems.push({ ...payload });
      }

      state.cartLength++
      if (!isNaN(state.cartTotalPrice)) {
        state.cartTotalPrice = (typeof state.cartTotalPrice === 'string' ? parseInt(state.cartTotalPrice) : state.cartTotalPrice) + payload.price
      } else {
        state.cartTotalPrice = payload.price
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      localStorage.setItem('cartLength', JSON.stringify(state.cartLength));
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice));
    },
    removeFromCart(state, { payload }) {
      if (payload.activeAdditionals || payload.activeOption) {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`).count = 0

        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption ? item.activeOption._id : '')}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption ? payload.activeOption._id : '')}${JSON.stringify(payload.activeAdditionals)}`).price -= payload.price / payload.count
      } else {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).count = 0

        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).price -= payload.price / payload.count
      }

      state.cartItems = [...state.cartItems.filter(item => item.count > 0)]

      let totalPrice = 0;

      state.cartItems.map(item => {
        totalPrice += item.price;
      })

      state.cartTotalPrice = totalPrice;

      localStorage.setItem('cartItems', JSON.stringify([...state.cartItems]))
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice))
    },
    removeCountFromCart(state, { payload }) {
      if (payload.activeAdditionals || payload.activeOption) {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption._id)}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption._id)}${JSON.stringify(payload.activeAdditionals)}`).count--

        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption._id)}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption._id)}${JSON.stringify(payload.activeAdditionals)}`).price -= payload.price / payload.count
      } else {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).count--
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).price -= payload.price / payload.count
      }

      state.cartItems = [...state.cartItems.filter(item => item.count > 0)]

      let totalPrice = 0;

      state.cartItems.map(item => {
        totalPrice += item.price;
      })

      state.cartTotalPrice = totalPrice;

      localStorage.setItem('cartItems', JSON.stringify([...state.cartItems]))
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice))
    },
    addCountProductCart(state, { payload }) {
      if (payload.activeOption || payload.activeAdditionals) {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption._id)}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption._id)}${JSON.stringify(payload.activeAdditionals)}`).price += payload.price / payload.count

        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id && `${JSON.stringify(item.activeOption._id)}${JSON.stringify(item.activeAdditionals)}` === `${JSON.stringify(payload.activeOption._id)}${JSON.stringify(payload.activeAdditionals)}`).count++
      } else {
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).count++
        // @ts-ignore
        state.cartItems.find(item => item._id === payload._id).price += payload.price / payload.count
      }

      let price = 0;

      state.cartItems.map(item => {
        price += item.price;
      })

      state.cartTotalPrice = price;

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      localStorage.setItem('cartLength', JSON.stringify(state.cartLength));
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice));
    },
    setCartItems(state, { payload }) {
      state.cartItems = [...payload];
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    setTotalCartPrice(state, { payload }) {
      state.cartTotalPrice = payload;
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice));
    },
    setCartLength(state, { payload }) {
      state.cartLength = payload;
      localStorage.setItem('cartLength', JSON.stringify(state.cartLength));
    },
    setOrderItems: (state, { payload }) => {
      state.cartItems = [...payload];
      let price = 0;
      payload.map((item: any) => {
        price += item.count * item.price
      })
      state.cartTotalPrice = price;
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice));
    },
    setCurrentOrderId: (state, { payload }) => {
      state.currentOrderId = payload;
    },
    changeCountInCart(state, { payload }) {
      if (payload.activeOption) {
        state.cartItems = [...state.cartItems.map(item => {
          if (item._id === payload._id && item.activeOption._id === payload.activeOption._id) {
            item.price = payload.count * (item.price / (item.count))
            if (item.oldPrice) {
              item.oldPrice = payload.count * (item.oldPrice / (item.count))
            }
            item.count = payload.count
          }
          return item
        })]
      } else {
        state.cartItems = [...state.cartItems.map(item => {
          if (item._id === payload._id) {
            item.price = payload.count * (item.price / (item.count))
            if (item.oldPrice) {
              item.oldPrice = payload.count * (item.oldPrice / (item.count))
            }
            item.count = payload.count
          }
          return item
        })]
      }

      let price = 0;

      state.cartItems.map(item => {
        price += item.price;
      })

      state.cartTotalPrice = price;

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      localStorage.setItem('cartLength', JSON.stringify(state.cartLength));
      localStorage.setItem('cartTotalPrice', JSON.stringify(state.cartTotalPrice));
    },
  },
  
})

export const { setCurrentOrderId, setTable, setCartItems, setTotalCartPrice, setCartLength,addToCart,
  removeFromCart,changeCountInCart,
  removeCountFromCart,
  addCountProductCart,
  setCurrentItem, toggleTheme, setHeaderVisible, setHeaderModal, toggleDishModal, changeTheme, setOrders, setCategories, setActiveCategory, setPopularProductsId, setProducts, setOrderItems } = mainSlice.actions

export default mainSlice.reducer
