import React, {useEffect, useState} from 'react';
import styles from './SelectTable.module.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../Redux/store";
import {Typography} from "@mui/material";
import {getSelectedTables, getTables} from "../../Hooks/hooks";

function Index() {

  const {theme} = useSelector((state: RootState) => state.MainSlice);
  const {restaurants, accessToken, user} = useSelector((state: RootState) => state.UserSlice);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [tables, setTables] = useState<any>([]);
  const [currentSelected, setCurrent] = useState<any>([])

  useEffect(() => {
    if(restaurants && accessToken) {
      getTables({accessToken: accessToken, id: restaurants[0]._id}).then(response => {
        setStart(response.tables.tablesCountStart)
        setEnd(response.tables.tablesCountEnd)
        let arr: any = [];
        for(let i = response.tables.tablesCountStart; i <= response.tables.tablesCountEnd; i++) {
          arr.push({number: i, status: 'unselected'})
        }
        getSelectedTables({accessToken: accessToken, id: restaurants[0]._id}).then(response2 => {
          response2.tables.map(item => {
            if(item.officiantId === user?._id) {
              setCurrent([...item.tables])
              arr.map((table: any) => {
                if(item.tables.includes(table.number)) {
                  table.status = 'selected';
                }
              })
            } else {
              arr.map((table: any) => {
                if(item.tables.includes(table.number)) {
                  table.status = 'already';
                }
              })
            }
          })
          arr.map((table: any) => {
            if(response.tables.unattendedTables && response.tables.unattendedTables.includes(table.number)) {
              table.status = 'unattended';
            }
          })
          setTables([...arr])
        })
      })
    }
  }, [restaurants, accessToken])

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <p className={styles.title}>Официант: <span>{user?.firstName} {user?.lastName}</span></p>
        <p className={styles.subtitle}>Столы: {currentSelected.join(', ')}</p>
        <div className={styles.tables}>
          {

          }
          {tables.map((item: any) => {
            return (
              <div key={item.status + item.number} style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${item.status === 'selected' ? styles.selected : item.status === 'unattended' ? styles.unattended : item.status === 'already' ? styles.already : ''}`}>
                {item.number}
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.item}>
          <div style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${styles.selected}`}>
            1
          </div>
          <p className={styles.infoText}><span>-</span> назначенный вам </p>
        </div>
        <div className={styles.item}>
          <div style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${styles.unattended}`}>
            1
          </div>
          <p className={styles.infoText}><span>-</span> необслуживаемые столы</p>
        </div>
        <div className={styles.item}>
          <div style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table}`}>
            1
          </div>
          <p className={styles.infoText}><span>-</span> стол не назначен</p>
        </div>
        <div className={styles.item}>
          <div style={{borderColor: theme === 'light' ? '#4B465C66' : '#8692D066', color: theme === 'light' ? 'rgba(75,70,92,0.52)' : 'rgba(134,146,208,0.69)'}} className={`${styles.table} ${styles.already}`}>
            1
          </div>
          <p className={styles.infoText}><span>-</span> уже назначен</p>
        </div>
      </div>
    </div>
  );
}

export default Index;
