import React from 'react';
import EditOrderContent from "../../Components/EditOrderContent/index";

function Index() {
  return (
    <div className={'order-page'}>
      <EditOrderContent/>
    </div>
  );
}

export default Index;
