import React from 'react';
import styles from './BackBtn.module.scss';
import {Icon} from "@iconify/react";
import {useSelector} from "react-redux";
import {RootState} from "../../Redux/store";
import {useNavigate} from "react-router-dom";

function Index() {

  const {theme} = useSelector((state: RootState) => state.MainSlice);

  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className={styles.btn} style={{color: theme === 'light' ? '#AAA9B3' : '#5A628D'}}>
      <Icon width={18} icon={'tabler:arrow-left'} color={theme === 'light' ? '#AAA9B3' : '#5A628D'}/>
      Назад
    </button>
  );
}

export default Index;
