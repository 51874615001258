import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../Redux/store";
import DishCount from "./dish/DishCount";
import { addToCart, changeCountInCart, removeFromCart, setCurrentItem, toggleDishModal } from "../Redux/Slices/MainSlice";
import { useAppSelector } from "../Hooks/useAppSelector";

type DishButtonsProps = {
  price?: number,
  type?: string,
  activeOption: {
    _id: string,
    title: string,
    price: number
  } | undefined,
  unit: any,
  count: number,
  setCount: any,
  activeAdditionals: {
    _id: string,
    title: string,
    price: number
  }[] | undefined,
  addPrice: number
}

const Button: React.FC<DishButtonsProps> = ({price, type, unit, activeOption, count, setCount, activeAdditionals, addPrice}) => {

  const modal = useAppSelector(state => state.MainSlice.dishModal)

  const dispatch = useDispatch();
  const location = useLocation();

  const addToCartLocal = () => {
    dispatch(toggleDishModal())
    setCurrentItem({...unit})
    activeAdditionals?.sort((a, b) => a.title > b.title ? 1 : -1);
    dispatch(addToCart({...unit, price: price ? (price + addPrice) * count : 0, oldPrice: (unit.oldPrice + (unit.oldPrice && activeOption?.price ? activeOption.price : 0) + addPrice !== 0 ?  unit.oldPrice + (unit.oldPrice && activeOption?.price ? activeOption.price : 0) + addPrice : 0) * count, activeOption: activeOption ? activeOption : undefined, count: count, activeAdditionals: activeAdditionals}))
  }

  const decreaseHandler = () => {
    if(location.pathname.substring(location.pathname.length - 5) === 'order') {
      if(count > 0) {
        setCount(count - 1);
        // dispatch(removeFromCart(unit))
      }
    } else {
      if(count > 1) {
        setCount(count - 1);
        // dispatch(removeFromCart(unit))
      }
    }
    
  }

  const increaseHandler = () => {
    setCount(count + 1);
    // dispatch(addToCart({...unit, price: price, activeOption: activeOption ? activeOption : undefined}))
  }


  const removeCart = () => {
    dispatch(removeFromCart(unit))
    dispatch(toggleDishModal())
    setCurrentItem({...unit})
  }

  const changeCount = () => {
    dispatch(toggleDishModal())
    setCurrentItem({...unit})
    dispatch(changeCountInCart({...unit, count: count}))
  }



  return (
    <div className="dish-buttons" style={{width: '100%'}}>
          <div
            className={`product-modal__addBtn`}
            onClick={() => count > 0 ? location.pathname.substring(location.pathname.length - 5) === 'order' ? changeCount() : addToCartLocal() : removeCart()}
            style={{backgroundColor: count === 0 ? '#E70F0F' : ''}}
          >
            {count > 0 ? location.pathname.substring(location.pathname.length - 5) === 'order' ? 'Готово' : `Добавить` : 'Удалить'}</div>
          <DishCount
            text={''}
            increaseHandler={increaseHandler}
            decreaseHandler={decreaseHandler}
            cardCount={count}
            type={type}
          />
          
          
    </div>
  )
}

export default Button;
