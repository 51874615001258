import React from "react";
import {useAppSelector} from "../../Hooks/useAppSelector";

type DishInfoItemProps = {
    type: string,
    image: string,
    quantity: number,
    measure: string
}

const DishInfoItem: React.FC<DishInfoItemProps> = ({type, image, quantity, measure}) => {
    const {theme} = useAppSelector(state => state.MainSlice)

    return (
        <div className="dish-info__item">
            <div className={`dish-info__item-image ${type === "weight" && "dish-info__item-image--weight"}`}>
                <img src={image} alt="" />
            </div>
            <div className={`dish-info__item-additional ${theme === "dark" && "dark"}`}>
                <span className="dish-info__item-quantity">{quantity}</span>
                <span>
                    {type === "weight" ? measure === 'grams' ? "г" : measure === 'kilograms' ? 'кг' : measure === 'milliliters' ? 'мл' : measure === 'liters' ? 'л' : measure === 'pieces' ? 'шт' : measure === 'portions' ? 'шт' : 'мин' : "мин"}
                </span>
            </div>
        </div>
    )
}

export default DishInfoItem;
