import React from 'react';
import styles from "./Input.module.scss";
import {useSelector} from "react-redux";
import {RootState} from "../../Redux/store";

function Input({changeFunc, value, placeholder, type, errorFunc, error}: {changeFunc: any, value: string, placeholder: string, type: string, errorFunc: any, error: {active: boolean, text: string}}) {
  const theme = useSelector((state: RootState) => state.MainSlice.theme);
  return (
    <>
      <input onChange={(e) => {
        changeFunc(e.target.value);
        errorFunc({
          active: false,
          text: ''
        })
      }} value={value} style={{borderColor: error.active ? 'red' : theme === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'}} placeholder={placeholder} type={type} className={styles.input}/>
      {
        error.active &&
        <div className={styles.errorBlock}>
          <p className={styles.errorText}>{error.text}</p>
        </div>
      }
    </>

  );
}

export default Input;
