import React, { useCallback, useEffect, useState } from 'react';
import styles from './AddOrderContent.module.scss'
import BackBtn from "../BackBtn";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Icon } from "@iconify/react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  addCountProductCart,
  addToCart,
  removeCountFromCart,
  removeFromCart,
  setCartItems,
  setCartLength,
  setCurrentItem,
  setOrderItems,
  setTable, setTotalCartPrice, toggleDishModal
} from "../../Redux/Slices/MainSlice";
import axios from "axios";

function Index() {

  const { cartTotalPrice, theme, cartItems, table } = useSelector((state: RootState) => state.MainSlice);
  const { user, accessToken, restaurants } = useSelector((state: RootState) => state.UserSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [wishes, changeWishes] = useState('');

  const showProductModalHandler = (value: boolean, unit: any) => {
    dispatch(setCurrentItem(unit))
    dispatch(toggleDishModal());
  }

  const start = useCallback(() => {
    if (restaurants && restaurants[0]._id === localStorage.getItem('restaurantId')) {
      if (localStorage.getItem('cartItems')) {
        // @ts-ignore
        dispatch(setCartItems(JSON.parse(localStorage.getItem('cartItems'))))
        // @ts-ignore
        dispatch(setCartLength(JSON.parse(localStorage.getItem('cartLength'))))
        // @ts-ignore
        dispatch(setTotalCartPrice(JSON.parse(localStorage.getItem('cartTotalPrice'))))
      }
    } else if (restaurants) {
      localStorage.setItem('restaurantId', restaurants[0]._id)
    }
  }, [restaurants])

  useEffect(() => {
    start();
  }, [restaurants])

  const acceptOrder = async () => {
    if (accessToken && restaurants) {
      try {
        const edit = await axios.post(`https://api.vkusclick.ru/v1/restaurants/${restaurants[0]._id}/orders`, {
          officiantId: user?._id,
          table: table,
          wishes: wishes,
          dishes: [...cartItems.map(item => {
            if (item.activeOption) {
              if (item.activeAdditionals && item.activeAdditionals.length > 0) {
                return { _id: item._id, quantity: item.count, option: item.activeOption.title, additionalls: [...item.activeAdditionals.map((item: any) => item.title)] }
              }
              return { _id: item._id, quantity: item.count, option: item.activeOption.title }
            } else {
              if (item.activeAdditionals && item.activeAdditionals.length > 0) {
                return { _id: item._id, quantity: item.count, additionalls: [...item.activeAdditionals.map((item: any) => item.title)] }
              }
              return { _id: item._id, quantity: item.count }
            }
          })]
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        if (edit.status === 201) {
          dispatch(setOrderItems([]));
          dispatch(setTable(''))
          navigate('/orders-list')
        }
      } catch (e) {

      }
    }
  }


  return (
    <>
      <BackBtn />
      <div className={styles.content}>
        <div className={styles.side}>
          <div className={styles.top}>
            <p className={styles.number}>Создание заказа</p>
            <div className={styles.tableWrap}>
              <p>Стол</p>
              <input value={table} onChange={(e) => dispatch(setTable(e.target.value.replace(/\D/, '')))} type="text" style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }} />
            </div>
          </div>
          <div className={`${styles.orderItems} ${theme === 'light' ? styles.light : styles.dark}`}>
            {
              cartItems.map(item => {
                return (
                  <div key={item._id} className={styles.order} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}>
                    <div onClick={() => showProductModalHandler(true, { ...item })} className={styles.imgWrap} style={{ backgroundImage: `url(${'https://s3.vkusclick.ru/' + item.image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                    </div>
                    <div className={styles.info}>
                      <p className={styles.orderTitle}>{item.title}</p>
                      <div className={styles.descr}>
                        <div>
                          <Icon icon={'ic:baseline-access-time'} color={'#FF9F43'} width={12} />
                          <p>{item.servingTime} мин</p>
                        </div>
                        <div>
                          <Icon icon={'bx:dish'} color={'#FF9F43'} width={12} />
                          <p>{item.quantity} {item.measure === 'grams' ? "г" : item.measure === 'kilograms' ? 'кг' : item.measure === 'milliliters' ? 'мл' : item.measure === 'liters' ? 'л' : item.measure === 'pieces' ? 'шт' : item.measure === 'portions' ? 'шт' : 'мин'}</p>
                        </div>
                      </div>
                      {
                        (item.activeOption || (item.activeAdditionals && item.activeAdditionals.length > 0)) &&
                        <div className="options">
                          <p>{`${item.activeOption ? item.activeOption.title : ''}${item.activeAdditionals && item.activeAdditionals.length > 0 ? item.activeOption ? ', ' + item.activeAdditionals.map((item: any) => item.title).join(', ') : item.activeAdditionals.map((item: any) => item.title).join(', ') : ''}`}</p>
                        </div>
                      }

                      <div className={styles.counter}>
                        <button onClick={() => dispatch(removeCountFromCart(item))} style={{ background: theme === 'dark' ? '#434968' : '#DFDFE3' }}>
                          <Icon color={theme === 'light' ? '#8D8B98' : '#9A9FBA'} icon={'ic:twotone-minus'} />
                        </button>
                        <p style={{ background: theme === 'dark' ? '#2F3349' : '#F8F7FA' }}>{item.count}</p>
                        <button onClick={() => dispatch(addCountProductCart(item))} style={{ background: theme === 'dark' ? '#434968' : '#DFDFE3' }}>
                          <Icon color={theme === 'light' ? '#8D8B98' : '#9A9FBA'} icon={'ic:twotone-plus'} />
                        </button>
                      </div>
                    </div>
                    <button onClick={() => dispatch(removeFromCart(item))} className={styles.close}>
                      <Icon color={theme === 'light' ? '#4B465C' : '#CFD3EC'} width={20} icon={'material-symbols:close-rounded'} />
                    </button>
                    <p className={styles.price}>{item.price} ₽</p>
                  </div>
                )
              })
            }
          </div>
          <NavLink to={'/menu?from=' + 'create-order'} className={styles.addBtn}>
            <Icon icon={'tabler:plus'} width={20} color={'#FF9F43'} />
            <p>Добавить в заказ</p>
          </NavLink>
        </div>
        <div className={styles.side}>
          <div className={styles.inputWrap}>
            <p className={styles.inputTitle}>Комментарии к заказу</p>
            <textarea value={wishes} onChange={(e) => changeWishes(e.target.value)} placeholder={'Коментарий'} style={{ borderColor: theme === 'light' ? '#DBDADE' : '#434968' }}></textarea>
          </div>
          <div className={styles.totalPrice}>
            <div className={styles.top} style={{ color: theme === 'light' ? '#C6C6C8' : '#7983BB', borderColor: theme === 'light' ? '#C6C6C8' : '#434968' }}>
              <p>Сумма заказа</p>
              <p>{cartTotalPrice} ₽</p>
            </div>
            <div className={styles.price}>
              <p>Итого</p>
              <p>{cartTotalPrice} ₽</p>
            </div>
          </div>
          <div className={styles.btns}>
            <button onClick={acceptOrder} style={{ opacity: cartTotalPrice === 0 || table.length === 0 ? '.7' : '' }} disabled={cartTotalPrice === 0 || table.length === 0} className={styles.green}>Создать заказ</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
