import React from 'react';
import LoginContent from "../../Components/LoginContent";

function Index() {
  return (
    <div className={'login-page'}>
      <LoginContent/>
    </div>
  );
}

export default Index;
