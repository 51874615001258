import axios from "axios";

export const getAccessToken = async ({refreshToken}: {refreshToken: string}) => {
  try {
    const token = await axios.post(`https://api.vkusclick.ru/v1/employees/refresh`, {
      refreshToken
    })
    return {refreshToken: token.data.refreshToken, accessToken: token.data.accessToken}
  } catch (e: any) {
    return new Error(e.message);
  }

}

export const getAuth = async ({accessToken}: {accessToken: string}) => {
  try {
    const user = await axios.get('https://api.vkusclick.ru/v1/employees/current', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    let company;
    if(user.data.emailConfirmed && user.data.role === 'director') {
      company = await axios.get('https://api.vkusclick.ru/v1/companies/' + user.data.companyId, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    } else {
      company = null
    }

    const restaurants = await axios.get('https://api.vkusclick.ru/v1/restaurants', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    return {user: {...user.data}, company: company ? {...company.data} : null, restaurants: [...restaurants.data], type: 'success'}
  } catch (e: any) {
    return {type: 'error'}
  }
}

export const getBalance = async ({accessToken, id}: {accessToken: string, refreshToken: string, id: string}) => {
  const result = await axios.get('https://api.vkusclick.ru/v1/companies/' + id, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return {...result.data}
}

export const getWorkers = async ({accessToken, id}: {accessToken: string, id: string}) => {
  try {
    const workers = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/employees`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },

    });
    return {workers: [...workers.data]}
  } catch (e) {
    return {type: 'error'}
  }
};

export const getCanAdd = async ({accessToken}: {accessToken: string}) => {
  try {
    const workers = await axios.get(`https://api.vkusclick.ru/v1/restaurants/can_add_check`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return {...workers.data}
  } catch (e) {
    return {type: 'error'}
  }
};

export const getTables = async ({accessToken, id}: {accessToken: string, id: string}) => {
  const tables = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/tables_settings`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {tables: {...tables.data}}
};

export const getSelectedTables = async ({accessToken, id}: {accessToken: string, id: string}) => {
  const tables = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/tables_assign`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {tables: [...tables.data]}
};

export const getPlaceInfo = async (code: string, accessToken: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${code}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result.data
  } catch (e) {

  }
}

export const getProducts = async ({id} : {id: string}) => {
  try {
    const products = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/dishes`)
    return {products: products.data, type: 'success'}
  } catch (e) {
    return {type: 'error'}
  }
}

export const getCategoryImages = async ({id}: {id: string}) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/categories/images`)
    return {type: 'success', ...result.data}
  } catch (e) {
    return {type: 'error'}
  }
}

export const getPopularDishes = async (accessToken: string, id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/popular_dishes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return {type: 'success', data: [...result.data]}
  } catch (e: any) {
  }
}

export const getOrderDishes = async (accessToken: string, id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/add_to_order_dishes`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return {type: 'success', data: [...result.data]}
  } catch (e: any) {
  }
}

export const getOrders = async ({id, accessToken}: {id: string, accessToken: string}) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/orders`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return {type: 'success', data: [...result.data]}
  } catch (e: any) {
  }
}

export const getDate = (date: any) => {
  const data = new Date(date)
  return `${data.getFullYear()}.${data.getMonth() < 10 ? '0' + data.getMonth() : data.getMonth()}.${data.getDay() < 10 ? '0' + data.getDay() : data.getDay()} ${data.getHours()}:${data.getMinutes()}`
}

export const getQrSettings = async ({id, accessToken}: {id: string, accessToken: string}) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/quick_response_code_settings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return {type: 'success', settings: {...result.data}}
  } catch (e) {
    return {type: 'error'}
  }
}

export const getNotice = async ({id, accessToken}: {id: string, accessToken: string}) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/notifications`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return [...result.data]
  } catch (e) {

  }
}

export function byField(field: any) {
  return (a: any, b: any) => a[field] > b[field] ? 1 : -1;
}

export const formatNumber = (num: number) => {
  const str = "" + num
  const pad = "0000"
  return (pad.substring(0, pad.length - str.length) + str);
}

export const getCurrentOrders = async ({id, accessToken}: {id: string, accessToken: string}) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/officiant_calls/current`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    const result2 = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/orders/current`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    return [...result.data, ...result2.data.map((item: any) => {
      return {
        _id: item._id,
        callNumber: item.orderNumber,
        officiantId: item.officiantId,
        status: item.status,
        table: item.table,
        type: 'order',
        updatedAt: item.updatedAt,
      }
    })].sort(byField('updatedAt')).reverse()
  } catch (e) {

  }
}


export const getCategories = async (id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/categories`);
    return [...result.data]
  } catch (e) {

  }
}


export const getDishes = async (id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/dishes`);
    return [...result.data]
  } catch (e) {

  }
}

export const getPopularId = async (id: string) => {
  try {
    const result = await axios.get(`https://api.vkusclick.ru/v1/restaurants/${id}/popular_dishes`);
    return [...result.data[0].dishesId]
  } catch (e) {

  }
}



