import React, {useRef} from 'react';
import BackBtn from "../../Components/BackBtn";
import MenuContent from "../../Components/MenuContent";

function Index() {

  const ref: any = useRef();

  return (
    <div ref={ref} className={'menu-page'}>
      <BackBtn/>
      <MenuContent refMenu={ref}/>
    </div>
  );
}

export default Index;
