import React from "react";
import timeIcon from "../../assets/images/time.svg";
import weightIcon from "../../assets/images/weight.svg";
import {setCurrentItem, toggleDishModal} from "../../Redux/Slices/MainSlice";
import DishInfoItem from "../dish/DishInfoItem";
import DishButtons from "../dish/DishButtons";
import {useAppDispatch} from "../../Hooks/useAppDispatch";

type CartProps = {
  unit: any
}

const Card: React.FC<CartProps> = ({unit}) => {
  const dispatch = useAppDispatch();

  const showProductModalHandler = (value: boolean, unit: any) => {
    dispatch(setCurrentItem(unit))
    dispatch(toggleDishModal());
  }

  return (
    <div className="card">
      <div style={{backgroundImage: `url(https://s3.vkusclick.ru/${unit.image})`, backgroundSize: 'cover', backgroundPosition: 'center'}} className="card-image__wrapper" onClick={() => showProductModalHandler(true, unit)}>
      </div>
      <div className="card-name">{unit.title}</div>
      <div className="dish-info">
        <DishInfoItem measure={unit.measure} type="time" image={timeIcon} quantity={unit.servingTime} />
        <DishInfoItem measure={unit.measure} type="weight" image={weightIcon} quantity={unit.quantity} />
      </div>
      <DishButtons onClick={() => showProductModalHandler(true, unit)} price={unit.price} unit={unit}/>
    </div>
  )
}

export default Card;
